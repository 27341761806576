<template>
  <BlokkliProvider v-bind="blokkliProps" :entity="props">
    <div class="container">
      <div class="pt-40 max-w-prose">
        <h1 v-if="title" class="is-1 lg:mb-40 mb-30">{{ title }}</h1>
        <div v-if="lead" class="is-lead lg:mb-40 mb-30" v-html="lead" />
        <div v-if="created" class="content is-small claim">
          {{ createdFormatted }}
        </div>
        <div
          class="flex flex-wrap divide-black divide-solid divide-x-2 lg:mb-60 mb-30"
        >
          <div
            v-for="tag in tags"
            :key="tag"
            class="px-10 first:pl-0 last:pr-0 mt-10 content is-small font-bold"
          >
            {{ tag }}
          </div>
        </div>
      </div>
      <div v-if="video || teaserImage" class="mb-30">
        <MediaRemoteVideo v-if="video && 'url' in video" v-bind="video" />
        <MediaVideo v-else-if="video && 'video' in video" v-bind="video" />
        <MediaImage
          v-else-if="teaserImage"
          v-bind="teaserImage"
          style-name="column_one"
        />
      </div>
    </div>
    <BlokkliField name="field_paragraphs" :list="paragraphs" />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeBlogArticleFragment } from '#graphql-operations'
import { falsy } from '~/helpers/type'

const props = defineProps<{
  title?: NodeBlogArticleFragment['title']
  lead?: NodeBlogArticleFragment['lead']
  teaserImage?: NodeBlogArticleFragment['teaserImage']
  video?: NodeBlogArticleFragment['video']
  createdField?: NodeBlogArticleFragment['createdField']
  tagsField?: NodeBlogArticleFragment['tagsField']
  paragraphs?: NodeBlogArticleFragment['paragraphs']
  created?: NodeBlogArticleFragment['created']
  blokkliProps: NodeBlogArticleFragment['blokkliProps']
}>()

const createdFormatted = computed(() =>
  new Date(props.created!).toLocaleDateString('de-CH'),
)
const tags = computed(() => props.tagsField?.map((v) => v.name).filter(falsy))
</script>
